'use client';

import { useEffect } from 'react';

export const NotFoundPage = () => {
  useEffect(() => {
    document.body.classList.add('h-full', 'overflow-hidden');
    document.getElementsByTagName('html')[0].classList.add('h-full');
  }, []);

  return (
    <main className="relative isolate min-h-full">
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="text-base font-semibold leading-8">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Page not found</h1>
        <p className="mt-4 text-base sm:mt-6">Nu s-a putut găsi pagina web pe care o cauți</p>

        <div className="mt-10 flex justify-center">
          <a href="/" className="text-sm font-semibold leading-7">
            <span aria-hidden="true">&larr;</span> Acasă
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
